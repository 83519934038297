import Header from '../../../componentes/Header';
import React, { useState, useEffect } from 'react';
import './DetalharMarcaSitePublico.css';
import { Dropdown } from 'primereact/dropdown';
import BottomSitePublico from '../../../componentes/BottomSitePublico';
import Cookies from 'js-cookie';
import { AutoComplete } from 'primereact/autocomplete';
import iconPesquisar from './iconPesquisar.svg';
import { useNavigate, useParams } from 'react-router-dom';
import iconVoltarPagina from './iconVoltarPagina.svg';

function DetalharMarcaSitePublico() {
  const navigate = useNavigate();
  const { idMarca } = useParams();

  // Estado geral
  const [mobile, setMobile] = useState(window.innerWidth <= 850);
  const [statusUsuario, setStatusUsuario] = useState({ logado: false, admin: false });

  // Estado de marcas
  const [marcaDetalhada, setMarcaDetalhada] = useState([]);

  // Estado de produtos
  const [produtos, setProdutos] = useState([]);
  const [filteredProdutos, setFilteredProdutos] = useState([]);
  const [produtosFiltrados, setProdutosFiltrados] = useState([]);

  //Estado dos filtros
  const [filtroNomeProduto, setFiltroNomeProduto] = useState('');
  const [linhaSelecionadaFiltro, setLinhaSelecionadaFiltro] = useState('');

  // Efeito para detectar mudança no tamanho da tela
  useEffect(() => {
    function handleResize() {
      setMobile(window.innerWidth <= 850);
    }
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Efeito para verificar autenticação
  useEffect(() => {
    const token = Cookies.get('token');
    if (token) {
      setStatusUsuario({ logado: true, admin: false });
      const fetchData = async () => {
        try {
          const headers = new Headers({ 'Authorization': `Bearer ${token}` });
          const response = await fetch(`${process.env.REACT_APP_API_URL}/autenticacao/verificarAdmin`, { headers });
          const data = await response.json();
          setStatusUsuario({ logado: true, admin: data });
        } catch (error) {
          setStatusUsuario({ logado: false, admin: false });
        }
      };
      fetchData();
    } else {
      setStatusUsuario({ logado: false, admin: false });
    }
  }, []);

  // Função para detalhar a marca
  async function detalharMarca() {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/marca/${idMarca}`, { method: 'GET' });
      const data = await response.json();
      setMarcaDetalhada(data);
    } catch (error) {
      alert(error);
    }
  }

  // Função para listar produtos da marca
  async function listarProdutos() {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/produto/listarProdutosPorMarca/${idMarca}`, { method: 'GET' });
      const data = await response.json();
      setProdutos(data);
    } catch (error) {
      alert(error);
    }
  }

  // Efeito para listar marcas e produtos ao carregar a página
  useEffect(() => {
    detalharMarca();
    listarProdutos();
    listarLinhas();
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  // Função para transformar byte em imagem
  function transformarByteEmImagem(itemData) {
    const byteCharacters = atob(itemData);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'image/png' });
    const url = window.URL.createObjectURL(blob);
    return url;
  }

  // Função para busca de produtos
  function searchProdutos(event) {
    setTimeout(() => {
      let _filteredProdutos;
      if (!event.query.trim().length) {
        _filteredProdutos = [...produtos];
      } else {
        _filteredProdutos = produtos.filter((produto) => {
          return produto.nome.toLowerCase().startsWith(event.query.toLowerCase());
        });
      }
      setFilteredProdutos(_filteredProdutos);
    }, 250);
  }

  // Efeito para filtrar produtos
  useEffect(() => {
    setProdutosFiltrados(produtos);
  }, [produtos]);

  function filtrar() {
    let filtrados = produtos;

    if (linhaSelecionadaFiltro.id !== null) {
      filtrados = filtrados.filter((produto) => produto.nomeLinha === linhaSelecionadaFiltro.nome);
    }

    if (filtroNomeProduto !== '') {
      if (filtroNomeProduto.nome) {
        filtrados = filtrados.filter((produto) => produto.nome.toLowerCase().startsWith(filtroNomeProduto.nome.toLowerCase()));
      } else {
        filtrados = filtrados.filter((produto) => produto.nome.toLowerCase().startsWith(filtroNomeProduto.toLowerCase()));
      }
    }

    setProdutosFiltrados(filtrados);
  }

  const [linhas, setLinhas] = useState([]);
  // Função para listar marcas
  async function listarLinhas() {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/linhasProdutos/listar`, { method: 'GET' });
      const data = await response.json();
      setLinhas([{ id: null, nome: 'Selecione a Linha' }, ...data]);
    } catch (error) {
      alert(error);
    }
  }
  return (
    <div className="sitePublicoDivMaior">
      <Header logado={statusUsuario.logado ? true : false} admin={statusUsuario.admin ? true : false} />
      <div className='divMaiorMobileSitePublico'>
        <div className='divFundoAzulCatalogoProdutosDetalharMarca' style={{ marginTop: '1em' }}>
          <div>
            <div>
              <div className='botaoDeVoltarDetalharMarca' onClick={() => navigate("/produtos")}>
                <img src={iconVoltarPagina} alt=''></img>
                Voltar a todos produtos
              </div>
            </div>
            <div>
              <h2 className='catalogoDeProdutosTitulo tituloDetalharMarca'>Marca {marcaDetalhada.nome}</h2>
              <div className='divInputsCatalogoDeProdutos inputsDoDetalharMarca'>
                <div className='divMaiorDoAutoCompleteFiltro classeDeWidthAuxiliarDetalharMarca'>
                  <img src={iconPesquisar} alt=''></img>
                  <AutoComplete
                    placeholder='Produto'
                    value={filtroNomeProduto}
                    suggestions={filteredProdutos}
                    completeMethod={searchProdutos}
                    field="nome"
                    onChange={(e) => setFiltroNomeProduto(e.target.value)}
                    className='autoCompleteDoFiltroProdutos'
                  />
                </div>
                <Dropdown className='dropdownSelecionarLinhaCatProdutos classeDeWidthAuxiliarDetalharMarca' value={linhaSelecionadaFiltro} onChange={(e) => setLinhaSelecionadaFiltro(e.value)} options={linhas} optionLabel='nome' placeholder='Selecione a Linha' panelClassName='abasDropdownFiltrosSitePublico'></Dropdown>
                <button className='botaoBuscarCatalogoDeProdutos' onClick={filtrar} style={{ cursor: 'pointer' }}>Buscar</button>
              </div>
            </div>
          </div>
        </div>
        <div className='divListagemDosProdutos' style={{ minHeight: '20em' }}>
          {
            produtosFiltrados.map((produto) => {
              return (
                <div title={`${produto.nome} da marca ${produto.nomeMarca}`} className='produtoDoCatProdutos' key={produto.id} style={{ cursor: 'pointer' }} onClick={() => navigate(produto.idMarca ? `/detalharProdutoSitePublico/${produto.id}` : `/detalharMarcaSitePublico/${produto.id}`)}>
                  <img src={transformarByteEmImagem(produto.imagem)} alt=''></img>
                  <label>{produto.nome}</label>
                </div>
              )
            })
          }
        </div>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          {marcaDetalhada.imagem ? <img style={{ paddingBottom: '10em', width: mobile ? '50%' : '15%', opacity: '50%' }} alt={`Logo da Marca ${marcaDetalhada.nome}`} src={transformarByteEmImagem(marcaDetalhada.imagem)}></img> : null}
        </div>
        <BottomSitePublico />
      </div >
    </div >
  );
}

export default DetalharMarcaSitePublico;
