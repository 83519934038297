import React, { useState, useEffect } from 'react';
import './BottomSitePublico.css';
import logoGirosBanner2 from './img/logoGirosBanner2.svg';
import iconeInstagramBottom from './img/iconeInstagramBottom.svg';
import iconeFacebookBottom from './img/iconeFacebookBottom.svg';
import fornecedoresBottom from './img/fornecedoresBottomPublico.svg';
import { Link } from 'react-router-dom';
import iconYoutube from './img/iconYoutubeHeader.svg';
const BottomSitePublico = () => {
    const [mobile, setMobile] = useState(window.innerWidth <= 850);

    useEffect(() => {
        function handleResize() {
            setMobile(window.innerWidth <= 850);
        }

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div style={{ flexDirection: 'column' }} className='bottomBottomFundoAzulEscuroSitePublico'>
          <div className='divCentroBottomFundoAzulEscuro'>
            <div className='primeiroDivBottomBottom'>
              <Link to={"/"}><img style={{ height: '46px', width: 'fit-content' }} src={logoGirosBanner2} alt='logoDaGirosPeças'></img></Link>
              <div style={{ display: 'flex', flexDirection: 'column', gap: '2em', marginTop: mobile ? '10%' : 0 }}>
                <label style={{ fontSize: '0.8em' }} className='textosBottomAzulEscuro'>Há mais de 30 anos, a confiança dos nossos clientes, bem como o bem estar e a segurança dos nossos colaboradores, são os pilares da nossa empresa. </label>
                <label style={{ fontSize: '0.8em' }} className='textosBottomAzulEscuro'>Nossa trajetória é repleta de dedicação, comprometimento e ideais.</label>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5em', marginTop: '10%' }}>
                <label className='textosBottomAzulEscuro'>Redes-sociais</label>
                <div style={{ display: 'flex', gap: '0.5em', alignItems: 'center' }}>
                  <Link style={{display: 'contents'}} target="_blank" rel="noopener noreferrer" to={"https://www.instagram.com/girospecas/"}><img style={{height: '1.5em'}} src={iconeInstagramBottom} alt='icone Instagram'></img></Link>
                  <Link style={{display: 'contents'}} target="_blank" rel="noopener noreferrer" to={"https://www.facebook.com/GirosPecasMotoresLtda/?locale=pt_BR"}><img style={{height: '1.5em'}} src={iconeFacebookBottom} alt='icone Facebook'></img></Link>
                  <Link style={{display: 'contents'}} target="_blank" rel="noopener noreferrer" to={"https://www.youtube.com/@girospecas"}><img style={{height: '1.5em'}} src={iconYoutube} alt="Youtube"/></Link>
                </div>
                {!mobile ? (<div style={{ display: 'flex', flexDirection: 'column', marginTop: '30%' }}>
                  <img style={{ height: '70px', width: '300px' }} src={fornecedoresBottom} alt='fornecedores do bottom'></img>
                </div>) : null}
              </div>
            </div>
            <div className='segundoDivSobreGirosParaVoce'>
              <div style={{ display: 'flex', flexDirection: 'column', minWidth: '12%', height: '100%', gap: '15%' }}>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '2em', marginTop: '40%' }}>
                  <label style={{ fontWeight: '700' }} className='textosBottomAzulEscuro'>SOBRE A GIROS</label>
                  <Link to={"/institucional"} className='linkBottomSitePublico'><label className='textosBottomAzulEscuro linkBottomSitePublico'>Institucional</label></Link>
                  <Link to={"/produtos"} className='linkBottomSitePublico'><label className='textosBottomAzulEscuro linkBottomSitePublico'>Catálogo de Produtos</label></Link>
                  <Link to={"/produtos"} className='linkBottomSitePublico'><label className='textosBottomAzulEscuro linkBottomSitePublico'>Fornecedores</label></Link>
                  <Link to={"/contato"} className='linkBottomSitePublico'><label className='textosBottomAzulEscuro linkBottomSitePublico'>Contato</label></Link>
                </div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', minWidth: '12%', height: '100%', gap: '7%' }}>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '2em', marginTop: '50%' }}>
                  <label style={{ fontWeight: '700' }} className='textosBottomAzulEscuro'>PARA VOCÊ</label>
                  <Link to={"/conteudos"} className='linkBottomSitePublico'><label className='textosBottomAzulEscuro linkBottomSitePublico'>Conteúdos</label></Link>
                  <Link to={"/trabalheConosco"} className='linkBottomSitePublico'><label className='textosBottomAzulEscuro linkBottomSitePublico'>Trabalhe conosco</label></Link>
                  <Link to={"/login"} className='linkBottomSitePublico'><label className='textosBottomAzulEscuro linkBottomSitePublico'>Área do cliente</label></Link>
                </div>
              </div>
            </div>
            {mobile ? (<hr className='linhaMobileDivisoria'></hr>) : null}
            <div className='ultimoDivBottomBottomAzulEscuro' style={{ display: 'flex', flexDirection: 'column', minWidth: '18%', height: '100%', marginTop: '3.4%' }}>
              <div style={{ borderBottom: '1px solid #003594', display: 'flex', flexDirection: 'column', paddingBottom: '1.2em', paddingLeft: mobile ? '5%' : 0 }}>
                <h3 className='tituloTerceiraColunaBottom'>Matriz - Distribuidora e Loja</h3>
                <label className='textosTerceiraColunaBottom'>GPL DISTRIBUIDORA E LOGISTICA LTDA</label>
                <label className='textosTerceiraColunaBottom'>CNPJ: 93.978.013/0001-10</label>
                <label className='textosTerceiraColunaBottom'>Av. Cairu, 1045 - Porto Alegre/RS</label>
                <label className='textosTerceiraColunaBottom'>(51) 3396-0300</label>
              </div>
              <div style={{ borderBottom: '1px solid #003594', display: 'flex', flexDirection: 'column', paddingBottom: '1.2em', paddingLeft: mobile ? '5%' : 0 }}>
                <h3 className='tituloTerceiraColunaBottom'>Filial - Distribuidora</h3>
                <label className='textosTerceiraColunaBottom'>Av. Ivo Lucchi, 207 - Palhoça/SC</label>
                <label className='textosTerceiraColunaBottom'>(48) 3380-3700</label>
              </div>
              <div style={{ borderBottom: '1px solid #003594', display: 'flex', flexDirection: 'column', paddingBottom: '1.2em', paddingLeft: mobile ? '5%' : 0 }}>
                <h3 className='tituloTerceiraColunaBottom'>Filial - Distribuidora</h3>
                <label className='textosTerceiraColunaBottom'>Rua Equador 275 D - Chapecó/SC</label>
                <label className='textosTerceiraColunaBottom'>(48) 3380-3700</label>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: mobile ? '5%' : 0 }}>
                <h3 className='tituloTerceiraColunaBottom'>Filial - Distribuidora</h3>
                <label className='textosTerceiraColunaBottom'>Rua Carlos Essenfelder, 2759 - Curitiba/PR</label>
                <label className='textosTerceiraColunaBottom'>(41) 3532-2900</label>
              </div>
              {mobile ? (<div style={{ display: 'flex', flexDirection: 'column', marginTop: '30%' }}>
                <img style={{ height: '70px', width: '300px' }} src={fornecedoresBottom} alt='fornecedores do bottom'></img>
              </div>) : null}
            </div>
          </div>
          <div className='todosDireitosReservadosTexto'>Todos os direitos reservados Giros Peças 2024</div>
        </div>
    );
}

export default BottomSitePublico;