import Header from '../../componentes/Header';
import './EsqueciMinhaSenha.css';
import { useLocation, useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
function EsqueciMinhaSenha() {
  const navigate = useNavigate();
  const location = useLocation();
  const emailEscrito = location && location.state ? location.state.emailEscrito : [];
  const [codigoDigitado, setCodigoDigitado] = useState("");
  const confirmarCodigo = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/autenticacao/confirmarCodigo`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: emailEscrito, codigo: codigoDigitado }),
      });
      if (response.ok) {
        const confirmacao = await response.json();
        if(confirmacao === true){
          navigate("/CriarNovaSenha" , { state: { emailEscrito}});
        }else{
          alert("Este email não corresponde a nenhuma conta em nosso sistema.");
        }
      } else {
        console.error('Erro');
      }
    } catch (error) {
      console.error('Erro: ', error);
    }
  };
  const handleCodigoDigitado = (event) => {
    setCodigoDigitado(event.target.value);
  }
  return (
    <div className="Login">
      <Header />
      <div className="contentLogin">
        <div className="pageContainer">
          <div className="divCriarNovaSenhaDeAcesso">
            <h3 className='criarNovaSenhaDeAcessoTitulo'>Criar nova senha de acesso</h3>
            <div className='divDoInputDeCodigoEnviadoPorEmail'>
              <label>Código enviado por e-mail</label>
              <input type="text" onChange={handleCodigoDigitado}></input>
            </div>
            <div onClick={confirmarCodigo} className='validarCodigoButton'>Validar código</div>
          </div>
        </div >
      </div>
    </div>
  );
}

export default EsqueciMinhaSenha;