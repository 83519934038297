import Header from '../../../componentes/Header';
import React, { useState, useEffect } from 'react';
import './ListagemLinhasAdmin.css';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import BotaoDeVoltarAdmin from '../../../componentes/BotaoDeVoltarAdmin';
import iconRemover from './imgs/iconRemoverAdmin.svg';
import iconEditar from './imgs/iconEditarAdmin.svg';
import iconFecharPopup from './imgs/iconFecharPopupAdmin.svg';

function ListagemLinhasAdmin() {

  const [linhaSelecionadaParaCadastrar, setLinhaSelecionadaParaCadastrar] = useState({ nome: '' });
  const [linhaSelecionadaParaEditar, setLinhaSelecionadaParaEditar] = useState({ id: '', nome: '' });
  const [idLinhaDeletar, setIdLinhaDeletar] = useState(null);

  const [linhas, setLinhas] = useState([]);
  const [statusUsuario, setStatusUsuario] = useState({ logado: false, admin: false });

  const [mostrarPopupCadastrar, setMostrarPopupCadastrar] = useState(false);
  const [mostrarPopupEditar, setMostrarPopupEditar] = useState(false);
  const [mostrarPopupDeletarLinha, setMostrarPopupDeletarLinha] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const token = Cookies.get('token');
    if (token) {
      const fetchData = async () => {
        try {
          const response = await fetch(`${process.env.REACT_APP_API_URL}/autenticacao/verificarAdmin`, {
            headers: { Authorization: `Bearer ${token}` },
          });
          const data = await response.json();
          setStatusUsuario({ logado: true, admin: data });

          if (!data) navigate('/login');
        } catch {
          setStatusUsuario({ logado: false, admin: false });
          navigate('/login');
        }
      };
      fetchData();
    } else {
      navigate('/login');
    }
  }, [navigate]);

  useEffect(() => {
    window.scrollTo(0, 0);
    listarLinhas();
  }, []);

  // Listar linhas
  async function listarLinhas() {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/linhasProdutos/listar`, { method: 'GET' });
      const data = await response.json();
      setLinhas(data);
    } catch (error) {
      alert(error);
    }
  }


  // Função para salvar alterações na linha
  async function salvarLinha() {
    try {
      const token = Cookies.get('token');
      const response = await fetch(`${process.env.REACT_APP_API_URL}/linhasProdutos/atualizar`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(linhaSelecionadaParaEditar),
      });

      if (response.ok) {
        setMostrarPopupEditar(false);
        listarLinhas();
        setLinhaSelecionadaParaEditar({ id: '', nome: '' });
      } else {
        console.log('Erro ao atualizar a linha.');
      }
    } catch (error) {
      alert('Erro ao se comunicar com o servidor.');
    }
  }

  // Função para cadastrar a nova linha
  async function cadastrarLinha() {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/linhasProdutos/criar`, {
        method: 'POST',
        headers: { 'Authorization': `Bearer ${Cookies.get('token')}` },
        body: linhaSelecionadaParaCadastrar.nome,
      });

      if (response.ok) {
        listarLinhas();
        setMostrarPopupCadastrar(false);
        setLinhaSelecionadaParaCadastrar({ id: "", nome: "" });
      } else {
        console.log("Erro ao cadastrar marca");
      }
    } catch (error) {
      console.log('Erro ao cadastrar marca: ' + error);
    }
  }

  // Função para deletar a linha
  async function deletarLinha() {
    try {
      if (idLinhaDeletar) {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/linhasProdutos/deletar/${idLinhaDeletar}`, {
          method: 'DELETE',
          headers: { 'Authorization': `Bearer ${Cookies.get('token')}` },
        });

        if (response.ok) {
          listarLinhas();
          setMostrarPopupDeletarLinha(false);
          setIdLinhaDeletar(null);
        } else {
          console.log("Erro ao deletar marca");
        }
      } else {
        console.log("Nenhuma marca selecionada para deletar")
      }
    } catch (error) {
      console.log('Erro ao deletar marca: ' + error);
    }
  }
  return (
    <div className="homeAdmin-wrapper">
      <Header logado={statusUsuario.logado} admin={statusUsuario.admin} />

      {mostrarPopupDeletarLinha && (
        <div className='marcasAdmin-popupCadastrarNovaMarca' style={{ maxWidth: '28em' }}>
          <h2>Excluir Linha</h2>
          <p style={{ marginBottom: '4em' }}>Você tem certeza que deseja excluir essa Linha? Essa ação não pode ser desfeita.</p>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <button style={{ padding: '1em 2em' }} className='admin-botaoAzulComHover' onClick={() => { setIdLinhaDeletar(null); setMostrarPopupDeletarLinha(false) }}>Não, cancelar</button>
            <button style={{ padding: '1em 2em' }} className='admin-botaoBrancoComHover' onClick={deletarLinha}>Sim, excluir</button>
          </div>
        </div>
      )}

      {mostrarPopupCadastrar && (
        <div className="marcasAdmin-popupCadastrarNovaMarca">
          <h2>Cadastrar linha <img onClick={() => { setMostrarPopupCadastrar(false); setLinhaSelecionadaParaCadastrar({ nome: '', imagem: '' }) }} alt='Fechar popup' src={iconFecharPopup}></img></h2>
          <div className="marcasAdmin-inputsDiv">
            <label>Nome Linha</label>
            <input
              type="text"
              value={linhaSelecionadaParaCadastrar.nome}
              onChange={(e) =>
                setLinhaSelecionadaParaCadastrar({ ...linhaSelecionadaParaCadastrar, nome: e.target.value })
              }
            />
            <button className='admin-botaoAzulComHover' onClick={cadastrarLinha}>Salvar</button>
          </div>
        </div>
      )}


      {mostrarPopupEditar && (
        <div className="marcasAdmin-popupCadastrarNovaMarca">
          <h2>Editar Linha <img onClick={() => { setMostrarPopupEditar(false); setLinhaSelecionadaParaEditar({ nome: '', imagem: '' }) }} alt='Fechar popup' src={iconFecharPopup}></img></h2>
          <div className="marcasAdmin-inputsDiv">
            <label>Nome Linha</label>
            <input
              type="text"
              value={linhaSelecionadaParaEditar.nome}
              onChange={(e) =>
                setLinhaSelecionadaParaEditar({ ...linhaSelecionadaParaEditar, nome: e.target.value })
              }
            />
            <button className='admin-botaoAzulComHover' onClick={salvarLinha}>Salvar</button>
          </div>
        </div>
      )}


      <div className="homeAdmin-content">
        <BotaoDeVoltarAdmin />
        <div className="marcasAdmin-titleDiv">
          <h1 className="homeAdmin-title">Linhas</h1>
          <button onClick={() => setMostrarPopupCadastrar(!mostrarPopupCadastrar)}>Cadastrar nova</button>
        </div>
        <div className="marcasAdmin-grid">
          <div className="marcasAdmin-grid-header" style={{gridTemplateColumns: '1fr 2fr'}} >
            <div className="header-item" style={{ paddingLeft: '1em'}}>Linha</div>
            <div className="header-item"></div>
          </div>
          {linhas.map((linha, index) => (
            <div className="marcasAdmin-grid-row" style={{gridTemplateColumns: '1fr 2fr'}} key={index}>
              <div className="grid-item" style={{ paddingLeft: '1em' }}>
                {linha.nome}
              </div>
              <div className="grid-item" style={{ textAlign: 'right', justifyContent: 'flex-end' }}>
                <div style={{display: 'flex'}}>
                  <button
                    onClick={() => {
                      setLinhaSelecionadaParaEditar(linhas[index]);
                      setMostrarPopupEditar(true);
                    }}
                  >
                    <img src={iconEditar} alt="" />Editar
                  </button>
                  <button onClick={() => { setIdLinhaDeletar(linha.id); setMostrarPopupDeletarLinha(true) }}><img src={iconRemover} alt="" /></button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ListagemLinhasAdmin;