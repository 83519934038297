import React, { useState, useEffect } from 'react';
import './ComponenteListaProdutos.css';
import { Dropdown } from 'primereact/dropdown';
import { AutoComplete } from 'primereact/autocomplete';
import iconPesquisar from './iconPesquisar.svg';
import { useNavigate } from 'react-router-dom';

const ComponenteListaProdutos = ( {versaoMini, onMarcasChange} ) => {
  const navigate = useNavigate();
  // Estado geral
  const [mobile, setMobile] = useState(window.innerWidth <= 850);

  // Estado de marcas
  const [marcas, setMarcas] = useState([]);
  const [nomesMarcas, setNomesMarcas] = useState([]);
  const [filteredMarcas, setFilteredMarcas] = useState([]);

  // Estado de produtos
  const [produtos, setProdutos] = useState([]);
  const [filteredProdutos, setFilteredProdutos] = useState([]);
  const [produtosFiltrados, setProdutosFiltrados] = useState([]);

  //Estado dos filtros
  const [filtroNomeProduto, setFiltroNomeProduto] = useState('');
  const [filtroNomeMarca, setFiltroNomeMarca] = useState('');
  const [linhaSelecionadaFiltro, setLinhaSelecionadaFiltro] = useState('');

  // Efeito para detectar mudança no tamanho da tela
  useEffect(() => {
    function handleResize() {
      setMobile(window.innerWidth <= 850);
    }
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  // Função para listar marcas
  async function listarMarcas() {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/marca/listar`, { method: 'GET' });
      const data = await response.json();
      setMarcas(data);
      setNomesMarcas(data);
      if(onMarcasChange){
        onMarcasChange(data);
      }
    } catch (error) {
      alert(error);
    }
  }

  // Função para listar produtos
  async function listarProdutos() {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/produto/listar`, { method: 'GET' });
      const data = await response.json();
      setProdutos(data);
    } catch (error) {
      alert(error);
    }
  }

  // Efeito para listar marcas e produtos ao carregar a página
  useEffect(() => {
    listarMarcas();
    listarProdutos();
    listarLinhas();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Função para transformar byte em imagem
  function transformarByteEmImagem(itemData) {
    const byteCharacters = atob(itemData);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'image/png' });
    const url = window.URL.createObjectURL(blob);
    return url;
  }

  // Função para busca de marcas
  function searchMarcas(event) {
    setTimeout(() => {
      let _filteredMarcas;
      if (!event.query.trim().length) {
        _filteredMarcas = [...nomesMarcas];
      } else {
        _filteredMarcas = nomesMarcas.filter((marca) => {
          if (event.query.nome) {
            return marca.nome.toLowerCase().startsWith(event.query.nome.toLowerCase());
          } else {
            return marca.nome.toLowerCase().startsWith(event.query.toLowerCase());
          }
        });
      }
      setFilteredMarcas(_filteredMarcas);
    }, 250);
  }

  // Função para busca de produtos
  function searchProdutos(event) {
    setTimeout(() => {
      let _filteredProdutos;
      if (!event.query.trim().length) {
        _filteredProdutos = [...produtos];
      } else {
        _filteredProdutos = produtos.filter((produto) => {
          return produto.nome.toLowerCase().startsWith(event.query.toLowerCase());
        });
      }
      setFilteredProdutos(_filteredProdutos);
    }, 250);
  }

  // Efeito para filtrar produtos
  useEffect(() => {
    setProdutosFiltrados(produtos);
  }, [produtos]);

  function filtrar() {
    let filtrados = produtos;

    if (linhaSelecionadaFiltro.id !== null) {
      filtrados = filtrados.filter((produto) => produto.nomeLinha === linhaSelecionadaFiltro.nome);
    }

    if (filtroNomeMarca !== '') {
      if (!filtroNomeProduto) {
        filtrados = marcas;
        if (filtroNomeMarca.nome) {
          filtrados = filtrados.filter((marca) => marca.nome.toLowerCase().startsWith(filtroNomeMarca.nome.toLowerCase()));
        } else {
          filtrados = filtrados.filter((marca) => marca.nome.toLowerCase().startsWith(filtroNomeMarca.toLowerCase()));
        }
      } else {
        if (filtroNomeMarca.nome) {
          filtrados = filtrados.filter((produto) => produto.nomeMarca.toLowerCase().startsWith(filtroNomeMarca.nome.toLowerCase()));
        } else
          filtrados = filtrados.filter((produto) => produto.nomeMarca.toLowerCase().startsWith(filtroNomeMarca.toLowerCase()));
      }
    }

    if (filtroNomeProduto !== '') {
      if (filtroNomeProduto.nome) {
        filtrados = filtrados.filter((produto) => produto.nome.toLowerCase().startsWith(filtroNomeProduto.nome.toLowerCase()));
      } else {
        filtrados = filtrados.filter((produto) => produto.nome.toLowerCase().startsWith(filtroNomeProduto.toLowerCase()));
      }
    }

    setProdutosFiltrados(filtrados);
  }
  function alterarItensFiltro(tipo){
    if(tipo === 'marca' && filtroNomeMarca === ''){
      setProdutosFiltrados(marcas)
    }else if(tipo === 'produto' && filtroNomeProduto === ''){
      setProdutosFiltrados(produtos)
    }
  }
  const [linhas, setLinhas] = useState([]);
    // Função para listar marcas
    async function listarLinhas() {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/linhasProdutos/listar`, { method: 'GET' });
        const data = await response.json();
        setLinhas([{id: null, nome: 'Selecione a Linha'}, ...data]);
      } catch (error) {
        alert(error);
      }
    }
  function handleKeyDown(event) {
    if (event.key === 'Enter') {
      filtrar();
    }
  }
  return (
    <div className="sitePublicoDivMaior" style={{marginTop: versaoMini && !mobile ? '-7vw' : '1em'}}>
      <div className='divMaiorMobileSitePublico'>
        <div className='divFundoAzulCatalogoProdutos' style={{ marginTop: '1em' }}>
          <div>
            <h2 className='catalogoDeProdutosTitulo'>Nossos Produtos</h2>
            <div className='divInputsCatalogoDeProdutos'>
              <div className='divMaiorDoAutoCompleteFiltro' onClick={() => alterarItensFiltro("produto")}>
                <img src={iconPesquisar} alt=''></img>
                <AutoComplete
                  placeholder='Produto'
                  value={filtroNomeProduto}
                  suggestions={filteredProdutos}
                  completeMethod={searchProdutos}
                  field="nome"
                  onChange={(e) => setFiltroNomeProduto(e.target.value)}
                  className='autoCompleteDoFiltroProdutos'
                  onKeyDown={handleKeyDown}
                />
              </div>
              <div className='divMaiorDoAutoCompleteFiltro' onClick={() => alterarItensFiltro("marca")}>
                <img src={iconPesquisar} alt=''></img>
                <AutoComplete
                  placeholder='Marca'
                  value={filtroNomeMarca}
                  suggestions={filteredMarcas}
                  completeMethod={searchMarcas}
                  field="nome"
                  onChange={(e) => setFiltroNomeMarca(e.target.value)}
                  className='autoCompleteDoFiltroProdutos'
                  onKeyDown={handleKeyDown}
                />
              </div>
              <Dropdown style={{paddingLeft: '2em'}} className='dropdownSelecionarLinhaCatProdutos' value={linhaSelecionadaFiltro} onChange={(e) => setLinhaSelecionadaFiltro(e.value)} options={linhas} optionLabel='nome' placeholder='Selecione a Linha' panelClassName='abasDropdownFiltrosSitePublico'></Dropdown>
              <button className='botaoBuscarCatalogoDeProdutos' onClick={filtrar} style={{ cursor: 'pointer' }}>Buscar</button>
            </div>
          </div>
        </div>
        <div className='divListagemDosProdutos' style={{ marginTop: !mobile ? '-1.5em' : '2em' }}>
          {
            versaoMini ? (
            produtosFiltrados.map((produto) => {
              return (
                <div className='produtoDoCatProdutos' title={produto.nomeMarca ? `${produto.nome} da marca ${produto.nomeMarca}` : `Marca ${produto.nome}`} key={produto.id} style={{cursor: 'pointer'}} onClick={() => navigate(produto.idMarca ? `/detalharProdutoSitePublico/${produto.id}` : `/detalharMarcaSitePublico/${produto.id}`)}>
                  <img src={transformarByteEmImagem(produto.imagem)} alt=''></img>
                  <label>{produto.nome}</label>
                </div>
              )
            })
            ):
            produtosFiltrados.slice(0, 10).map((produto) => {
              return (
                <div className='produtoDoCatProdutos' title={produto.nomeMarca ? `${produto.nome} da marca ${produto.nomeMarca}` : `Marca ${produto.nome}`} key={produto.id} style={{cursor: 'pointer'}} onClick={() => navigate(produto.idMarca ? `/detalharProdutoSitePublico/${produto.id}` : `/detalharMarcaSitePublico/${produto.id}`)}>
                  <img src={transformarByteEmImagem(produto.imagem)} alt=''></img>
                  <label>{produto.nome}</label>
                </div>
              )
            })
          }
        </div>
      </div >
    </div >
  );
}

export default ComponenteListaProdutos;
