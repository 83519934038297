import Header from '../../../componentes/Header';
import React, { useState, useEffect } from 'react';
import './Contato.css';
import Cookies from 'js-cookie';
import iconEmail from './imgs/iconeEmail.svg';
import iconTelefone from './imgs/iconeTelefone.svg';
import banner from './imgs/bannerPaginaContato.png';
import iconeUnidades from './imgs/iconeUnidadesPaginaContato.svg';
import BottomSitePublico from '../../../componentes/BottomSitePublico';
import { Link } from 'react-router-dom';

function Contato() {
  const [mobile, setMobile] = useState(window.innerWidth <= 850);
  const [statusUsuario, setStatusUsuario] = useState({ logado: false, admin: false });
  const [formulario, setFormulario] = useState({ nome: "", telefone: "", email: "", mensagem: "" });

  useEffect(() => {
    function handleResize() {
      setMobile(window.innerWidth <= 850);
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const token = Cookies.get('token');
    if (token) {
      const fetchData = async () => {
        try {
          const headers = new Headers({ 'Authorization': `Bearer ${token}` });
          const response = await fetch(`${process.env.REACT_APP_API_URL}/autenticacao/verificarAdmin`, { headers });
          const data = await response.json();
          setStatusUsuario({ logado: true, admin: data });
        } catch (error) {
          setStatusUsuario({ logado: false, admin: false });
        }
      };
      fetchData();
    }
  }, []);

  const handleFormularioChange = (campo, valor) => {
    if (campo === "telefone") {
      setFormulario({ ...formulario, telefone: formatarTelefone(valor) });
    } else {
      setFormulario({ ...formulario, [campo]: valor });
    }
  };

  const formatarTelefone = (valor) => {
    valor = valor.replace(/\D/g, "");
    valor = valor.replace(/^(\d{2})(\d)/g, "($1) $2");
    valor = valor.replace(/(\d)(\d{4})$/, "$1-$2");
    return valor;
  };

  const enviarFormulario = async () => {
    if (!formulario.nome || !formulario.telefone || !formulario.email || !formulario.mensagem) {
      alert("Preencha todos os campos do formulário");
      return;
    }
    try {
      const headers = new Headers({
        'Content-Type': 'application/json',
      });

      const response = await fetch(`${process.env.REACT_APP_API_URL}/email/enviarFormContato`, {
        method: 'POST',
        headers,
        body: JSON.stringify(formulario),
      });

      if (response.ok) {
        alert("Mensagem enviada com sucesso");
        setFormulario({ nome: "", telefone: "", email: "", mensagem: "" });
      } else {
        alert("Erro ao enviar mensagem");
      }
    } catch (error) {
      console.log(error);
      alert("Erro ao enviar mensagem");
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="sitePublicoDivMaior">
      <Header logado={statusUsuario.logado ? true : false} admin={statusUsuario.admin ? true : false} />
      <div className='divMaiorMobileSitePublico'>
        <div className='divFundoBrancoPaginaContato'>
          <div className='divMaiorTituloContato'>
            <div className='divDoTituloESubtituloPaginaContato'>
              <h1 className='tituloPaginaContato'>Entre em contato conosco</h1>
              <p className='textoTituloPaginaContato'>Estamos aqui para ajudar. Preencha o formulário, ligue ou envie um e-mail para entrar em contato. Esperamos poder atendê-lo em suas necessidades.</p>
              <div className='divDoTelefoneEEmailPagContato'>
                <label><img alt='icone de telefone' src={iconTelefone} />central de atendimento: (51) 4003-8386</label>
                <label><img alt='Icone de email' src={iconEmail} />eliane@girospecas.com.br</label>
                <label><img alt='Icone de email' src={iconEmail} />atendimento@girospecas.com.br</label>
              </div>
            </div>
            <div className='divDosInputsPaginaCOntato'>
              <div className='divInputPagContato'>
                <label>Nome</label>
                <input value={formulario.nome} onChange={(e) => handleFormularioChange("nome", e.target.value)} type='text' />
              </div>
              <div className='divInputPagContato'>
                <label>Telefone</label>
                <input pattern="\(\d{2}\) \d{4,5}-\d{4}" maxLength="15" value={formulario.telefone} onChange={(e) => handleFormularioChange("telefone", e.target.value)} type='tel' />
              </div>
              <div className='divInputPagContato'>
                <label>E-mail</label>
                <input value={formulario.email} onChange={(e) => handleFormularioChange("email", e.target.value)} type='email' />
              </div>
              <div className='divInputPagContato'>
                <label>Mensagem</label>
                <textarea value={formulario.mensagem} onChange={(e) => handleFormularioChange("mensagem", e.target.value)} />
              </div>
              <button className='botaoEnviarMensagemPagContato' onClick={enviarFormulario}>Enviar mensagem</button>
            </div>
          </div>
          {!mobile && (<div className='bannerPaginaContato'><img style={{ width: '100%' }} src={banner} alt="Banner de Contato" /></div>)}
        </div>
        <h2 className='tituloNossasUnidadesPaginaContato'>Nossas Unidades</h2>
        <div className='divUnidadesPaginaTrabalheConosco'>
          <div className='divDaUnidadePaginaTrabalheConosco'>
            <Link title='Veja como chegar' to={'https://www.google.com/maps/dir//Av.+Cairú,+1045+-+Navegantes,+Porto+Alegre+-+RS,+90230-030/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x951979eb8eaf7999:0x5de1dd18d137e62c?sa=X&ved=1t:707&ictx=111'} target="_blank"><label style={{ cursor: 'pointer', fontWeight: '700', gap: '0.5em', display: 'flex', alignItems: 'center' }}><img alt='icone simbolizando localização' src={iconeUnidades}></img>Matriz - Distribuidora e Loja Porto Alegre</label></Link>
            <label>Av. Cairu, 1045 - Porto Alegre/RS </label>
            <label>(51) 3396-0300</label>
          </div>
          <div className='divDaUnidadePaginaTrabalheConosco'>
          <Link title='Veja como chegar' to={'https://www.google.com/maps/dir//R.+Carlos+Essenfelder,+2759+-+Boqueirão,+Curitiba+-+PR,+81730-060/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x94dcfaee858725e7:0x47ab45af1fa9127a?sa=X&ved=1t:707&ictx=111&cshid=1726014740236059'} target="_blank"><label style={{ cursor: 'pointer', fontWeight: '700', gap: '0.5em', display: 'flex', alignItems: 'center' }}><img alt='icone simbolizando localização' src={iconeUnidades}></img>Filial - Distribuidora Curitiba</label></Link>
            <label>Rua Carlos Essenfelder, 2759 - Curitiba/PR</label>
            <label>(41) 3532-2900</label>
          </div>
          <div className='divDaUnidadePaginaTrabalheConosco'>
          <Link title='Veja como chegar' to={'https://www.google.com/maps/dir//Av.+Ivo+Luchi,+207+-+Jardim+Eldorado,+Palhoça+-+SC,+88133-510/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x952735937c0a2785:0x7177c3b853f1bb8c?sa=X&ved=1t:707&ictx=111'} target="_blank"><label style={{ cursor: 'pointer', fontWeight: '700', gap: '0.5em', display: 'flex', alignItems: 'center' }}><img alt='icone simbolizando localização' src={iconeUnidades}></img>Filial - Distribuidora Palhoça</label></Link>
            <label>Av. Ivo Lucchi, 207 - Palhoça/SC</label>
            <label>(48) 3380-3700</label>
          </div>
          <div className='divDaUnidadePaginaTrabalheConosco'>
          <Link title='Veja como chegar' to={'https://www.google.com/maps/dir//R.+Equador,+275d+-+Líder,+Chapecó+-+SC,+89805-210/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x94e4b5d651df00c9:0x59d7a44b7a058bea?sa=X&ved=1t:707&ictx=111'} target="_blank"><label style={{ cursor: 'pointer', fontWeight: '700', gap: '0.5em', display: 'flex', alignItems: 'center' }}><img alt='icone simbolizando localização' src={iconeUnidades}></img>Filial - Distribuidora Chapecó</label></Link>
            <label>Rua Equador 275 D - Chapecó/SC</label>
            <label>(48) 3380-3700</label>
          </div>
        </div>
        <BottomSitePublico />
      </div>
    </div>
  );
}

export default Contato;